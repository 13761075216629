import angular from 'angular';
import IFormattedStepSchema from '@Angular.Runner/interfaces/IFormattedStepSchema';

angular.module('fg').controller('fgFormController', [
  '$scope',
  '$parse',
  function ($scope, $parse) {
    this.model = {} as IFormModel;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    // Called by the directive
    this.init = function (
      dataExpression,
      schema,
      state,
      editMode,
      stepId,
      flowInstanceId,
      previousDataExpression,
      flowFields,
      isWorkflow,
      formModelId,
      isPublic
    ) {
      //make the associated step Id available to all form inputs
      self.stepId = stepId;
      self.flowInstanceId = flowInstanceId;
      self.editMode = editMode;

      self.flowFields = flowFields;
      self.isWorkflow = isWorkflow;
      self.formModelId = formModelId;
      self.isPublic = isPublic === 'true';

      const dataGetter = $parse(dataExpression);
      const dataSetter = dataGetter.assign;

      $scope.$watch(dataGetter, function (value) {
        if (value === undefined) {
          value = {};

          if (dataSetter) {
            dataSetter($scope, value);
          }
        }

        self.model.data = value;
      });

      $scope.$watch(
        function () {
          return schema.model(); // schema-controller.model()
        },
        function (value: IFormattedStepSchema) {
          if (value === undefined) {
            schema.model({});
          } else {
            self.model.schema = value;
          }
        }
      );
      const previousDataGetter = $parse(previousDataExpression);
      const previousDataSetter = previousDataGetter.assign;
      $scope.$watch(previousDataGetter, function (value) {
        if (value === undefined) {
          value = {};

          if (previousDataSetter) {
            previousDataSetter($scope, value);
          }
        }

        self.model.previousCustomValidationData = value;
      });
      self.model.state = state;

      return self.model;
    };
  }
]);

export interface IFormModel {
  data: unknown;
  schema: IFormattedStepSchema;
  previousCustomValidationData: unknown;
  state: unknown;
}
